import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import constants from "../../constants";
import logoUri from "../../media/header-logo.png";

import styles from "./Header.module.scss";

const MobileLogo = () => {
    return (
        <NavLink to="/" className={cx(styles.Logo)}>
            <img src={logoUri} style={{ height: 70, width: 'auto' }} alt="Insights by eoStar"></img>
        </NavLink>
    );
};

export default MobileLogo;
