import React from "react";
import LoginForm from "../components/Auth/LoginForm";
import { useAuth } from "../contexts/Auth";

import styles from "../components/Page.module.scss";

function Login() {
    const { login, loginError } = useAuth();

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <LoginForm login={login} loginError={loginError} />
        </div>
    );
}

export default Login;
