const DashboardConfig = {
    dashboard1: {
        id: 0,
        title: 'Dashboard 1',
        dashboardId: 'aa9mtRV9hxz8'
    },
    dashboard2: {
        id: 1,
        title: 'Dashboard 2',
        dashboardId: 'aepzuJYxfVwO'
    },
    salesGoals: {
        id: 2,
        title: 'Sales Goals',
        dashboardId: 'aabRRAQyapdq'
    },
    revenueAndCostActivity: {
        id: 3,
        title: 'Revenue and Cost Activity',
        dashboardId: 'aanSOXSYhQPn'
    },
    salesAndRevenueKPIs: {
        id: 4,
        title: 'Sales and Revenue KPIs',
        dashboardId: 'aaJSIooxbhGW'
    },
    casesDeliveredAndReturned: {
        id: 5,
        title: 'Cases Delivered and Returned',
        dashboardId: 'aaBSHf43dUX1'
    },
    bestAndWorstSellingBrands: {
        id: 6,
        title: 'Best and Worst Selling Brands',
        dashboardId: 'aadS3mOfdvpd'
    },
    truckUtilization: {
        id: 7,
        title: 'Truck Utilization',
        dashboardId: 'acoVzbs9dbwS'
    },
    pickFloorReplenishments: {
        id: 8,
        title: 'Pick Floor Replenishments',
        dashboardId: 'aaFVYlwPbJVC'
    },
    pickFloorPerformance: {
        id: 9,
        title: 'Pick Floor Performance',
        dashboardId: 'acuVVdxmfDCv'
    },
    inventoryLevels: {
        id: 10,
        title: 'Inventory Levels',
        dashboardId: 'acWVHsIZei4u'
    },
    crossdockInventoryBreakdown: {
        id: 11,
        title: 'Cross Dock Invntory Breakdown',
        dashboardId: 'ahSWf9LfaKQB',
    },
    hotshotDrivers: {
        id: 12,
        title: 'HotShot Drivers',
        dashboardId: 'ad8WrSULgYkf',
    },
    underperformingRetailersAndProducts: {
        id: 13,
        title: 'Underperforming Retailers and Products',
        dashboardId: 'acfWPPt8gwWk'
    },
    presellerPowerScoreSummary: {
        id: 14,
        title: 'Preseller PowerScore Summary',
        dashboardId: 'aaRW8KHQgdki'
    },
    retailerPowerScoreSummary: {
        id: 15,
        title: 'Retailer PowerScore Summary',
        dashboardId: 'aeyWWSI8aXf7'
    },
    productPowerScoreSummary: {
        id: 16,
        title: 'Product PowerScore Summary',
        dashboardId: 'adlWX6M3czMJ'
    },
    operatingExpenseReport: {
        id: 17,
        title: 'Operating Expense Report',
        dashboardId: 'ae2WUTLUhTH1'
    },
    salesGrowth: {
        id: 18,
        title: 'Sales Growth',
        dashboardId: 'abpXkHYdf8q9'
    },
    offScheduleDeliveries: {
        id: 19,
        title: 'Off Schedule Delivery Drivers',
        dashboardId: 'aaJxHs7sd3EO'
    },
};

export default DashboardConfig;