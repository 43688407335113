import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import EmbeddedDashboard from '../routes/EmbeddedDashboard';
import DashboardConfig from './DashboardConfig';


const useStyles = makeStyles((theme) => ({
    
}));

const requiredDashboardViews = [
    DashboardConfig.salesAndRevenueKPIs,
    DashboardConfig.revenueAndCostActivity,
    DashboardConfig.salesGrowth,
    DashboardConfig.retailerPowerScoreSummary,
    DashboardConfig.productPowerScoreSummary,
    DashboardConfig.presellerPowerScoreSummary,
];

const ExecutiveDashboard = (props) => {
    const classes = useStyles();
    return (
        <EmbeddedDashboard
            config={requiredDashboardViews}
        />
    );
};

export default ExecutiveDashboard;
