const ProjectConfig = {
    wright: {
        projectId: 'p792zk75z20gt9dk02u3w9vuy1wr0ros',
    },
    straub: {
        projectId: 'nvt7o10ts6wjriclhf7wlny19c8sph7v',
    },
    durango: {
        projectId: 'v6vtqjdxpaqnqbkn1pfzh8t1s4cx325m'
    },
};

export default ProjectConfig;