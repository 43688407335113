import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import "@gooddata/react-components/styles/css/main.css";

import Login from "./Login";
import Logout from "./Logout";
import ExecutiveDashboard from "../dashboards/ExecutiveDashboard";
import FinanceDashboard from "../dashboards/FinanceDashboard";
import OperationsDashboard from "../dashboards/OperationsDashboard";
import SalesDashboard from "../dashboards/SalesDashboard";
import SupplierDashboard from "../dashboards/SupplierDashboard";
import WarehouseOperationsDashboard from "../dashboards/WarehouseOperationsDashboard";

import styles from "./AppRouter.module.scss";
// Uncomment these lines if you want to redirect unauthorized users to login form
import { useAuth } from "../contexts/Auth";
const RedirectIfNotLoggedIn = () => {
    const auth = useAuth();
    const user = auth.data;
    const isLoading = auth.isLoading;
    const shouldRedirectToLogin = !isLoading && !user;
    return shouldRedirectToLogin ? <Route component={() => <Redirect to="/login" />} /> : null;
};

const AppRouter = () => {
    return (
        <div className={styles.AppRouter}>
            <Router>
                <RedirectIfNotLoggedIn />
                <Route exact path="/" component={ExecutiveDashboard} />
                <Route exact path="/executive" component={ExecutiveDashboard} />
                <Route exact path="/finance" component={FinanceDashboard} />
                <Route exact path="/sales" component={SalesDashboard} />
                <Route exact path="/operations" component={OperationsDashboard} />
                <Route exact path="/warehouse" component={WarehouseOperationsDashboard} />
                <Route exact path="/supplier" component={SupplierDashboard} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/logout" component={Logout} />
            </Router>
        </div>
    );
};

export default AppRouter;
