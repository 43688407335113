import React, { useState, useEffect } from "react";

import Page from "../components/Page";
import { backend } from "../constants";
import { makeStyles } from '@material-ui/core/styles';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import pageStyles from '../components/Page.module.scss';
import { useAuth } from '../contexts/Auth';

import styles from './EmbeddedDashboard.module.scss';


const useStyles = makeStyles((theme) => ({
    
}));

const Embedded = (props) => {
    const classes = useStyles();
    const { projectId } = useAuth();
    return (projectId &&
        <Page>
            <Tabs>
                <TabList>
                {
                    props.config.map(item => {
                        if (item) {
                            return (
                                <Tab key={item.id}>
                                    <p>{item.title}</p>
                                </Tab>
                            );
                        } else {
                            return null;
                        }
                    })
                }
                </TabList>

                {
                    props.config.map(item => {
                        if (item) {
                            return (
                                <TabPanel key={item.id}>
                                    <iframe 
                                        className={pageStyles.IframeRow}
                                        loading="lazy"
                                        src={backend + "/dashboards/embedded/#/project/" + projectId + "/dashboard/"+ item.dashboardId +"?showNavigation=false&disabledEditMode=true"}></iframe>
                                </TabPanel>
                            );
                        } else {
                            return null;
                        }
                    })
                }
            </Tabs>
        </Page>
    );
};

export default Embedded;
