import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import EmbeddedDashboard from '../routes/EmbeddedDashboard';
import DashboardConfig from './DashboardConfig';



const useStyles = makeStyles((theme) => ({
    
}));

const requiredDashboardViews = [
    DashboardConfig.salesAndRevenueKPIs,
    DashboardConfig.revenueAndCostActivity,
    DashboardConfig.operatingExpenseReport,
];

const FinanceDashboard = (props) => {
    const classes = useStyles();
    return (
        <EmbeddedDashboard
            config={requiredDashboardViews}
        />
    );
};

export default FinanceDashboard;
