import React from "react";

import styles from "./Footer.module.scss";

const Footer = () => {
    return (
        <footer className={styles.Footer}>
            <section className={styles.Copyright}>
                Copyright © 2021 eoStar. All Rights Reserved.
            </section>
        </footer>
    );
};

export default Footer;
