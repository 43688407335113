import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../contexts/Auth';
import EmbeddedDashboard from '../routes/EmbeddedDashboard';
import DashboardConfig from './DashboardConfig';
import ProjectConfig from "./ProjectConfig";


const useStyles = makeStyles((theme) => ({
    
}));

const requiredDashboardViews = [
    DashboardConfig.salesAndRevenueKPIs,
    DashboardConfig.casesDeliveredAndReturned,
    DashboardConfig.hotshotDrivers,
    DashboardConfig.retailerPowerScoreSummary,
    DashboardConfig.productPowerScoreSummary,
    DashboardConfig.presellerPowerScoreSummary,
    DashboardConfig.truckUtilization,
];

const OperationsDashboard = (props) => {
    const classes = useStyles();
    const { projectId } = useAuth();

    const dashboardViews = [ProjectConfig.wright.projectId, ProjectConfig.durango.projectId].includes(projectId) ? 
        [...requiredDashboardViews, DashboardConfig.offScheduleDeliveries] : requiredDashboardViews;

    return (
        <EmbeddedDashboard
            config={dashboardViews}
        />
    );
};

export default OperationsDashboard;
