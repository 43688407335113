import React from "react";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import styles from "./Header.module.scss";

const Links = () => {
    return (
        <>
            <NavLink to={"/executive"} className={styles.Link} activeClassName={styles.LinkActive} exact>
                Executive
            </NavLink>
            <NavLink to={"/finance"} className={styles.Link} activeClassName={styles.LinkActive} exact>
                Finance
            </NavLink>
            <NavLink to={"/sales"} className={styles.Link} activeClassName={styles.LinkActive} exact>
                Sales
            </NavLink>
            <NavLink to={"/operations"} className={styles.Link} activeClassName={styles.LinkActive} exact>
                Operations
            </NavLink>
            <NavLink to={"/warehouse"} className={styles.Link} activeClassName={styles.LinkActive} exact>
                Warehouse Operations
            </NavLink>
            <NavLink to={"/supplier"} className={styles.Link} activeClassName={styles.LinkActive} exact>
                Supplier Rep
            </NavLink>
        </>
    );
};

export default Links;
